<template>
    <div>
        <div v-if="quiz.header" :style="quiz.header" class="quiz-head"></div>
        <div class="quiz-user-info">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 mx-auto">
                        <div class="row align-items-center">
                            <form
                                @submit.prevent="sendUserData()"
                                class="col-lg-6 col-xl-7 info"
                            >
                                <h3
                                    class="
                                        text-capitalize
                                        font-weight-bold
                                        mb-1
                                    "
                                >
                                    {{ quiz.name }}
                                </h3>
                                <p class="font-weight-600 mb-4">
                                    Enter your information
                                </p>

                                <div v-if="errorMsg" class="mb-3">
                                    <b-alert show variant="danger">{{
                                        errorMsg
                                    }}</b-alert>
                                </div>

                                <!-- name -->
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                v-model.trim="fullName"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('fullName')"
                                                :class="{
                                                    error: errors.includes(
                                                        'fullName'
                                                    ),
                                                }"
                                            />
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="errors.includes('fullName')"
                                                class="input-error mt-1"
                                                >{{ $t("fullNameError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input
                                                v-model.trim="fName"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('firstname')"
                                                :class="{
                                                    error: errors.includes(
                                                        'fName'
                                                    ),
                                                }"
                                            />
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="errors.includes('fName')"
                                                class="input-error mt-1"
                                                >{{ $t("fNameError") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input
                                                v-model.trim="lName"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('lastname')"
                                                :class="{
                                                    error: errors.includes(
                                                        'lName'
                                                    ),
                                                }"
                                            />
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="errors.includes('lName')"
                                                class="input-error mt-1"
                                                >{{ $t("lNameError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- email -->
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <input
                                                v-model.trim="email"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('email')"
                                                :class="{
                                                    error: errors.includes(
                                                        'email'
                                                    ),
                                                }"
                                            />
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="errors.includes('email')"
                                                class="input-error mt-1"
                                                >{{ $t("emailError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <!-- country -->
                                <div class="row">
                                    <div class="col">
                                        <div
                                            class="form-group"
                                            :class="{
                                                error: errors.includes(
                                                    'country'
                                                ),
                                            }"
                                        >
                                            <v-select
                                                :options="countries"
                                                label="text"
                                                @input="input"
                                                v-model="country"
                                                paceholder="Select your country"
                                            ></v-select>
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="
                                                    errors.includes('country')
                                                "
                                                class="input-error mt-1"
                                                >{{ $t("countryError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <!-- Specialty -->
                                <div class="row">
                                    <div class="col">
                                        <div
                                            :class="{
                                                error: errors.includes(
                                                    'specialty'
                                                ),
                                            }"
                                            class="form-group"
                                        >
                                            <v-select
                                                :options="specialtyData"
                                                label="text"
                                                @input="inputSpecialty"
                                                v-model="specialty"
                                                paceholder="Select your specialty"
                                            ></v-select>
                                            <!-- <input
                                                v-model.trim="specialty"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('specialty')"
                                                :class="{
                                                    error: errors.includes(
                                                        'specialty'
                                                    ),
                                                }"
                                            /> -->
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="
                                                    errors.includes('specialty')
                                                "
                                                class="input-error mt-1"
                                                >{{ $t("specialtyError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <!-- Sub specialty -->
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <input
                                                v-model.trim="subSpecialty"
                                                type="text"
                                                class="form-control"
                                                :placeholder="
                                                    $t('subSpecialty')
                                                "
                                                :class="{
                                                    error: errors.includes(
                                                        'subSpecialty'
                                                    ),
                                                }"
                                            />
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="
                                                    errors.includes(
                                                        'subSpecialty'
                                                    )
                                                "
                                                class="input-error mt-1"
                                                >{{ $t("subSpecialtyError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <!-- Licence NO. -->
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <input
                                                v-model.trim="licence"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('licenceNO')"
                                                :class="{
                                                    error: errors.includes(
                                                        'licence'
                                                    ),
                                                }"
                                            />
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="
                                                    errors.includes('licence')
                                                "
                                                class="input-error mt-1"
                                                >{{ $t("licenceError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <!-- Hospital -->
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <input
                                                v-model.trim="hospital"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('hospital')"
                                                :class="{
                                                    error: errors.includes(
                                                        'hospital'
                                                    ),
                                                }"
                                            />
                                            <img
                                                class="error-icon"
                                                width="22"
                                                src="@/assets/imgs/error-icon.png"
                                                alt="error"
                                            />
                                            <small
                                                v-if="
                                                    errors.includes('hospital')
                                                "
                                                class="input-error mt-1"
                                                >{{ $t("hospitalError") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <button
                                                type="submit"
                                                class="
                                                    btn btn-quiz
                                                    text-uppercase
                                                    btn-block
                                                "
                                                :disabled="sending"
                                            >
                                                <b-spinner
                                                    v-if="sending"
                                                    variant="light"
                                                ></b-spinner>
                                                <span v-else class="text-white"
                                                    >Get Quiz</span
                                                >
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div
                                class="
                                    col-lg-6 col-xl-5
                                    vector
                                    d-none d-lg-block
                                "
                            >
                                <img
                                    src="@/assets/imgs/doc.png"
                                    class="mw-100 mx-auto"
                                    alt="doc"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="quiz.footer" class="quiz-footer">
            <div class="container sm-no-padding">
                <div :style="quiz.footer" class="quiz-footer-img"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getCountries,
    getSpecialty,
    sendUserFormData,
    getQuiz,
} from "@/API/Quiz";
// import CountryPicker from "@/components/CountryPicker";
export default {
    name: "UserInfo",
    data() {
        return {
            sending: false,
            countries: [],
            specialtyData: [],
            quiz: {
                id: null,
                name: null,
                is_login: 0,
                header: null,
                footer: null,
            },
            fName: null,
            lName: null,
            fullName: null,
            email: null,
            country: "Select your country",
            specialty: "Select your specialty",
            subSpecialty: null,
            licence: null,
            hospital: null,
            errors: [],
            errorMsg: null,
            inputsNotEmpty: [
                "fullName",
                "email",
                "country",
                "specialty",
                "subSpecialty",
                // "licence",
                "hospital",
            ],
            inputCharsetLength: ["fullName", "subSpecialty", "hospital"],
        };
    },
    computed: {
        code() {
            return this.$route.query?.code || 0;
        },
        quizData() {
            return this.$store.state.quiz?.quizData || null;
        },
    },
    methods: {
        getCurrentQuiz() {
            if (!this.code) {
                this.$router.push("/404");
                return;
            }
            getQuiz(this.code, 1).then((res) => {
                if (res?.status == 200) {
                    const responseData = res?.data?.data;

                    this.$store.commit("quiz/UPDATEQUIZDATA", responseData);

                    this.quiz = responseData;
                    this.quiz.header = `background: url(${responseData.header}) center center no-repeat;`;
                    this.quiz.footer = `background: url(${responseData.footer}) center center no-repeat;`;
                    return;
                }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                this.$router.push("/404");
            });
        },
        updateCountriesData() {
            getCountries().then((res) => {
                if (res.status == 200) {
                    const countriesData = res.data?.data;
                    const countries = countriesData.map((c) => {
                        c.value = c.code;
                        c.text = c.name;
                        return c;
                    });

                    this.countries = countries;
                    // this.country = "Select your country";
                }
            });
        },
        updateSpecialtyData() {
            getSpecialty().then((res) => {
                if (res.status == 200) {
                    const specialtyData = res.data?.data;
                    const specialties = specialtyData.map((s) => {
                        s.value = s.id;
                        s.text = s.name;
                        return s;
                    });

                    this.specialtyData = specialties;
                    // this.specialty = "Select your specialty";
                }
            });
        },
        input(value) {
            this.country = value;
        },
        inputSpecialty(value) {
            this.specialty = value;
        },
        checkInputsValidation() {
            const inputs = this.inputsNotEmpty;
            const errArr = this.errors;
            inputs.forEach((input) => {
                const index = errArr.indexOf(input);
                if (!this[input]) {
                    if (index == -1) {
                        errArr.push(input);
                    }
                } else {
                    if (index > -1) {
                        errArr.splice(index, 1);
                    }
                }
            });

            if (!this.specialty?.value) {
                this.errors.push("specialty");
            } else {
                const i = this.errors.indexOf("specialty");
                this.errors.splice(i, 1);
            }

            if (errArr?.length) {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });

                return false;
            }
        },
        validateInputCharsetLength(input) {
            if (this.errors.includes(input)) {
                return;
            }
            if (this[input]?.length < 3) {
                this.errors.push(input);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                return;
            }
        },
        validateEmail() {
            if (this.errors.includes("email")) {
                return;
            }
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isValidEmail = re.test(String(this.email).toLowerCase());
            if (!isValidEmail) {
                this.errors.push("email");
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                return;
            }
            const index = this.errors.indexOf("email");
            if (index > -1) {
                this.errors.splice(index, 1);
            }
        },
        sendUserData() {
            this.checkInputsValidation();

            this.validateEmail();

            if (!this.country?.value) {
                this.errors.push("country");
            } else {
                const i = this.errors.indexOf("country");
                this.errors.splice(i, 1);
            }

            this.inputCharsetLength.forEach((input) => {
                this.validateInputCharsetLength(input);
            });

            if (!this.errors.length) {
                this.sending = true;
                const collectedData = {
                    quiz_id: this.quiz.id,
                    // first_name: this.fName,
                    // last_name: this.lName,
                    full_name: this.fullName,
                    email: this.email,
                    country_id: this.country?.id,
                    Specialty_id: this.specialty?.id,
                    sub_Specialty: this.subSpecialty,
                    license: this.licence,
                    facility_name: this.hospital,
                };
                sendUserFormData(collectedData).then((res) => {
                    this.errorMsg = null;
                    // console.log(res);
                    this.sending = false;
                    if (res.status == 200) {
                        // console.log(res.data);
                        this.$store.commit(
                            "quiz/UPDATERESPONSEID",
                            res.data.details
                        );
                        this.$store.commit("quiz/TOGGLEUSERDATACOLLECTED", {
                            isCollected: true,
                            collectedData,
                        });
                        this.$router.push(`/quiz/${this.code}`);

                        return;
                    }

                    if (res?.status == 500) {
                        this.$router.push("/500");
                        return;
                    }

                    if (res.response?.data?.message) {
                        this.errorMsg = res.response.data.message;
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }
                });
            }
        },
    },
    mounted() {
        this.getCurrentQuiz();
        this.updateCountriesData();
        this.updateSpecialtyData();
    },
};
</script>
